import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { ClosableBox } from '../../../molecules/ClosableBox';
import { Overlay } from '../../../atoms/Overlay';
import { Fade } from '../../../atoms/Fade';
import { ImageTakeover } from '../../../../ImageTakeover';
import { facePaint } from '../../../../../domain/theme/utils/facePaint';
import { t } from '../../../../../domain/services/configService';
import { ContentPackageWrapper } from '../../ContentPackageWrapper';
import { ContainerFluid } from '../../../atoms/ContainerFluid';
import { GridColumn, GridRow } from '../../../atoms/Grid';
import { CardItemVerticalLegacy } from '../../CardItemVerticalLegacy';

export const ContentTakeOverExpandedForPhotographer = ({
  imageEntity,
  cards,
  closeCb
}) => {
  return (
    <Fade show position="fixed" zIndex="999">
      <Overlay color="#000">
        <ClosableBox
          closeColor="#fff"
          closeCb={closeCb}
          closeIconPosition={{ top: 20, right: 30 }}
        >
          <ImageTakeover entity={imageEntity} />
          <ContentPackageWrapper
            contentPackageHeader={t('Appears on:')}
            backgroundColor="true_black"
            extraStyles={css`
              ${facePaint({
                'contain-intrinsic-size': [
                  '0 1720px',
                  '0 403px',
                  '0 405px',
                  '0 470px'
                ]
              })}
            `}
          >
            <ContainerFluid>
              <GridRow gutter="none">
                {cards.map((card, index) => {
                  const key = `cardGlobalMedium2_${index}`;

                  return (
                    <GridColumn
                      width={[12, 4, 4, 4]}
                      childrenMarginBottom="l"
                      key={key}
                    >
                      <CardItemVerticalLegacy
                        size="third"
                        data={card}
                        backgroundColor="black"
                        imageMaxWidths={{
                          tablet: 341,
                          small_desktop: 426,
                          large_desktop: 533
                        }}
                      />
                    </GridColumn>
                  );
                })}
              </GridRow>
            </ContainerFluid>
          </ContentPackageWrapper>
        </ClosableBox>
      </Overlay>
    </Fade>
  );
};

ContentTakeOverExpandedForPhotographer.propTypes = {
  imageEntity: PropTypes.objectOf(PropTypes.any).isRequired,
  cards: PropTypes.arrayOf(PropTypes.any).isRequired,
  closeCb: PropTypes.func.isRequired
};
